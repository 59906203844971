<template>
  <div>
    <div class="title">{{ $t("MyPosition") }}</div>
    <el-table v-if="btnList[0].select" :data="tableData">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="tableEmpty"></div>
      </template>
      <el-table-column
        prop="investmentStatus"
        :label="$t('investmentStatus')"
        width="140"
        align="center"
      >
        <template v-slot="scope">
          <span>{{ scope.row.statusText }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="username" :label="$t('entryName')" width="170" align="center">
        <template v-slot="scope">
          <span>{{ scope.row.financOrder && scope.row.financOrder.user && scope.row.financOrder.user.username }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderAmount"
        :label="$t('InvestmentAmount')"
        width="170"
        align="center"
      ><template v-slot="scope">
        {{scope.row.orderAmount | toThousandFilterTwo}}
      </template></el-table-column>
      <el-table-column
        prop="tokenNum"
        :label="$t('investCreate.tokenTitle')"
        width="120"
        align="center"
      >
        <template v-slot="scope">{{ Number(scope.row.token) }}</template>
      </el-table-column>
      <el-table-column prop="interestRate" :label="$t('Yield')" width="120" align="center">
        <template
          v-slot="scope"
        >{{ scope.row.financOrder && scope.row.financOrder.interestRate ? scope.row.financOrder.interestRate + '%' : '0%' }}</template>
      </el-table-column>
      <el-table-column prop="planBenefit" :label="$t('TotalIncome')" width="200" align="center">
        <template
          v-slot="scope"
        >{{ (scope.row.planBenefit ? Number(scope.row.planBenefit).toFixed(2) : '0.00') | toThousandFilterTwo }}</template>
      </el-table-column>
      <el-table-column prop="shipmentDate" :label="$t('valueTime')" width="150" align="center">
        <template
          v-slot="scope"
        >{{ scope.row.financOrder && scope.row.financOrder.shipmentDate }}</template>
      </el-table-column>
      <el-table-column
        prop="promisedPaymentDate"
        :label="$t('MaturityInvestment')"
        width="150"
        align="center"
      >
        <template
          v-slot="scope"
        >{{ scope.row.financOrder && scope.row.financOrder.promisedPaymentDate }}</template>
      </el-table-column>
      <el-table-column :label="$t('operation')" min-width="100" align="center">
        <template v-slot="scope">
          <el-button
            size="small"
            class="btn-black"
            @click="handleDelete(scope.row)"
          >{{ $t("details") }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>

<script>
import { differenceInDays } from "date-fns";
import AdPagination from "@/components/ad-pagination";
import filter from "@/filters";
import { BigNumber } from 'bignumber.js';
export default {
  components: {
    AdPagination
  },
  data() {
    return {
      btnList: [{ title: 'itemsUnderAppointment', select: true }, { title: 'itemsInRevenue', select: false }, { title: 'completedProjects', select: false }],
      tableData: [],
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      tableLoading: false,
      tableEmpty: this.$t('loading'),
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.getTableList();
    },
    getTableList() {
      this.tableLoading = true;
      const data = {
        page: this.tablePageCP,
        pageSize: this.tablePagePS
      };
      this.$axios.get('/v1/invest/invest-orders', { params: data }).then((response) => {
        if (response.code === 0 || response.code === '0') {
          this.tableData = response.data.rows || [];
          this.tableTotal = response.data.count || 0;
          this.tableLoading = false;
          this.tableEmpty = this.$t('tableEmpty');
          this.tableData.map((item) => {
            item.statusText = filter.myInvestStatus((item.status).toString());
            item.orderAmount = item.orderAmount ? Number(item.orderAmount) / this.$enums.UNIT_MILLION : '0.00';
            // Token
            const orderAmount = Number(item.orderAmount);
            const tokenNum = new BigNumber(orderAmount);
            item.tokenNum = tokenNum.times(0.001);
          });
        } else {
          this.tableLoading = false;
          this.tableEmpty = this.$t('tableEmpty');
          this.$message({ type: 'error', message: result.message });
        }
      }).catch(error => {
        this.tableLoading = false;
        this.tableEmpty = this.$t('tableEmpty');
      });
    },
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background:#81d8d0;color:#fff;';
      }
    },
    handleDelete(rows) {
      // localStorage.setItem(
      //   'investDateils',
      //   JSON.stringify(rows)
      // );
      this.$router.push({ path: '/invest-find/pro-detail', query: { id: rows.financOrder.id } });
    },
    typeClick(item, index) {
      this.btnList.forEach(item => {
        item.select = false;
      });
      this.btnList[index].select = true;
    },
    formatStatus(financingType) {
      let t = '';
      switch (financingType) {
        case 'Fundraising':
          t = this.$t('inRevenue');
          break;
        case 'Funded':
          t = this.$t('inRevenue');
          break;
        case 'Repaid':
          t = this.$t('repaid');
          break;
        default:
          t = financingType;
      }
      return t;
    },
  }
};
</script>

<style lang="scss" scoped>
.title-view {
  height: 100px;
  border: 1px solid #979797;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 30px;
  .require {
    margin-top: 10px;
    span {
      color: #81d8d0;
    }
  }
  .btn-black {
    margin-left: 50px;
  }
}
</style>
